import { parents } from "@devowl-wp/react-utils";
import { getLastClickedConnectedCounter } from ".";
import { HTML_ATTRIBUTE_BLOCKER_CONNECTED, HTML_ATTRIBUTE_BLOCKER_ID, HTML_ATTRIBUTE_BY, HTML_ATTRIBUTE_GOT_CLICKED, HTML_ATTRIBUTE_GOT_CLICKED_VALUE, HTML_ATTRIBUTE_GOT_CLICKED_VALUE_DO_NOT_USE_IN_NESTED } from "..";

/**
 * Detect if a given unblocked node is the last clicked element so we can delegate the click
 * event to the unblocking mechanism. This allows us e.g. autoplay for YouTube.
 */
function detectLastClicked(node) {
  const connectedBlocker = node.getAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED);
  const consentId = node.getAttribute(HTML_ATTRIBUTE_BLOCKER_ID);
  const consentBy = node.getAttribute(HTML_ATTRIBUTE_BY);
  const lastClicked = getLastClickedConnectedCounter();
  let isLastClicked = `${lastClicked}` === connectedBlocker;
  if (isLastClicked) {
    node.setAttribute(HTML_ATTRIBUTE_GOT_CLICKED, HTML_ATTRIBUTE_GOT_CLICKED_VALUE);
  } else {
    // Check if there is a parent unblocked element which got clicked and we can
    // use it as our "click"-indicator. This allows us to delegate click elements to
    // the first unblocked, nested element.
    const [parentGotClicked] = parents(node, `[${HTML_ATTRIBUTE_GOT_CLICKED}="${HTML_ATTRIBUTE_GOT_CLICKED_VALUE}"][${HTML_ATTRIBUTE_BLOCKER_ID}="${consentId}"][${HTML_ATTRIBUTE_BY}="${consentBy}"]`);
    if (parentGotClicked) {
      parentGotClicked.setAttribute(HTML_ATTRIBUTE_GOT_CLICKED, HTML_ATTRIBUTE_GOT_CLICKED_VALUE_DO_NOT_USE_IN_NESTED);
      isLastClicked = true;
    }
  }
  return isLastClicked;
}
export { detectLastClicked };